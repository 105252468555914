@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  scrollbar-color: #a0aec0 transparent;
  scrollbar-width: thin;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* border-radius: 100vh; */
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  border-radius: 100vh;
  border: 3px solid transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #a0aec0; */
}

::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

::-webkit-calendar-picker-indicator:hover {
  background-color: #eee;
  border-radius: 5px;
}